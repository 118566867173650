import request from '../request';
export const basePath = '/tenant-center/tenant';

/**
 * @name 获取标签列表
 * @returns Promise<{ any[] }>
 */
export function getGuideCompanyList(current, size, keyWord = '') {
  return request(
    `${basePath}/enterprise/page?current=${current}&size=${size}&keyword=${keyWord}`,
    {},
  );
}
