<template>
  <div :class="$style.wrap">
    <a-form-model :class="$style.model" layout="inline" labelAlign="left">
      <div>
        <a-form-model-item>
          <a-input
            v-model="searchData.content"
            placeholder="设备号/固件版本"
            @input.stop="() => searchByDeviceName()"
            style="width: 200px;"
          >
            <a-icon
              @click="() => searchByDeviceName()"
              slot="suffix"
              type="search"
            />
          </a-input>
        </a-form-model-item>
        <a-form-model-item :class="$style.item">
          <compony-select
            v-model="searchData.tenantId"
            style="width: 200px;"
            @change="() => searchByContent()"
          />
        </a-form-model-item>
        <a-form-model-item :class="$style.item">
          <a-select
            style="width: 150px"
            v-model="searchData.projectId"
            placeholder="全部项目"
            allow-clear
            @change="() => searchByContent()"
            show-search
            option-filter-prop="children"
            :filter-option="true"
          >
            <a-select-option
              v-for="item in project"
              :key="item.pkId"
              :value="item.pkId"
            >
              {{ item.projectName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :class="$style.item">
          <a-select
            style="width: 120px"
            v-model="searchData.status"
            placeholder="全部状态"
            allow-clear
            show-search
            option-filter-prop="children"
            :filter-option="true"
            @change="() => searchByContent()"
          >
            <a-select-option
              v-for="item in status"
              :key="item.key"
              :value="item.key"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            type="primary"
            size="small"
            @click="clearSelected"
            style="height: 32px;"
          >
            重置
          </a-button>
        </a-form-model-item>
      </div>
      <div>
        <a-form-model-item>
          <div
            @click="deviceIn"
            v-if="$p.action('CREATE')"
            :class="$style.export"
          >
            <a-icon type="select" />
            <span>导入</span>
          </div>
        </a-form-model-item>
      </div>
    </a-form-model>
    <a-table
      :columns="columns"
      :data-source="data"
      :row-selection="rowSelection()"
      :scroll="{ x: 2800 }"
      :pagination="pagination"
      :loading="loading"
      @change="showSizeChange"
      ref="table"
    >
      <template slot="deviceName" slot-scope="text, record">
        <div :class="$style.deviceCell">
          <span>{{ text }}</span>
          <span
            :style="{
              backgroundColor: hatColors[record.color],
              color: '#ffffff',
              fontSize: '16px',
            }"
          >
            <span :class="$style.plusTxt" v-if="record.type === 'PLUS'"
              >plus</span
            >
            <x-icon v-else type="tc-icon-hat-smile" />
          </span>
        </div>
      </template>
      <template slot="deviceSecretIntercept" slot-scope="record">
        <div>
          <li
            v-if="record.secretShow"
            style="display:flex;justify-content: space-between;"
          >
            <span>{{ record.deviceSecretIntercept }}</span>
            <a-icon
              type="eye-invisible"
              :class="$style.iconStyle"
              @click="secretShow(record)"
            />
          </li>
          <li v-else style="display:flex;justify-content: space-between;">
            <span>**********</span>

            <a-icon
              type="eye"
              :class="$style.iconStyle"
              @click="secretShow(record)"
            />
          </li>
        </div>
      </template>
      <template slot="userName" slot-scope="record">
        <div>
          <AImage
            v-if="record.userName && record.userAvatar"
            :src="record.userAvatar"
          />
          <span>{{ record.userName }}</span>
        </div>
      </template>
      <template slot="status" slot-scope="status">
        <span>{{ deviceStatus(status) }}</span>
      </template>

      <template slot="type" slot-scope="type">
        <span>{{ { BASE: '基础版', PLUS: '升级版' }[type] }}</span>
      </template>
      <template slot="detailUrl" slot-scope="record">
        <a v-if="record.detailUrl" :href="record.detailUrl" target="_blank"
          >已添加</a
        >
        <a v-else href="javascript:;">-</a>
      </template>
      <template slot="operation" slot-scope="record">
        <a
          v-if="$p.action('UPDATE')"
          href="javascript:;"
          style="margin-right: 10px;"
          @click="edit(record)"
          >编辑</a
        >
        <a
          v-if="record.tenantName && $p.action('UPDATE')"
          href="javascript:;"
          style="margin-right: 10px;"
          @click="removeDevice(record.deviceId)"
          >移除</a
        >
        <a
          v-if="!record.tenantName && $p.action('DELETE')"
          href="javascript:;"
          style="margin-right: 10px;"
          @click="deleteDevice(record.deviceId)"
          >删除</a
        >
        <a
          href="javascript:;"
          style="margin-right: 10px;"
          @click="Management(record)"
          >管理</a
        >
        <a href="javascript:;" @click="deviceInfo(record)">更多</a>
      </template>
    </a-table>
    <div :class="$style.bottom">
      <a-form-model layout="inline">
        <a-form-model-item>
          <a-button
            type="primary"
            @click="downloadQrcodes"
            style="margin-right: 10px;"
            size="small"
            >下载</a-button
          >
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            type="primary"
            style="margin-right: 10px;"
            size="small"
            @click="showUpgrade"
            >OTA升级</a-button
          >
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" size="small" @click="batchAllocation"
            >批量分配</a-button
          >
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" size="small" @click="voiceRecognition"
            >语音识别</a-button
          >
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { createFormModal, createModal } from '@triascloud/x-components';
import DeviceAdd from '../device-add/index.vue';
import {
  getDeviceList,
  addDeviceText,
  reqDeleteDevice,
  reqGetProject,
  reqRemoveDevice,
  speechRecognition,
} from '@/services/hat';
import Tip from '@/components/tip';
import Edit from '../edit';
import DeviceInfo from '../device-info';
import { clipboardWrite } from '@triascloud/utils';
import AImage from '@/components/a-image';
import { debounce } from 'lodash-decorators';
import ExcelError from '../device-add/excel/excel-error.vue';
import DownloadQrcode from '../download-qrcode/';
import Upgrade from '../upgrade/index.vue';
import BatchAllocation from '../batch-allocation/index.vue';
import ComponySelect from '@/views/digital-site/components/compony-select.vue';
import VoiceRecognition from '../voice-recognition/index.vue';

const hatColors = {
  WHITE: '#ededed',
  RED: '#F60000',
  ORANGE: '#FF8707',
  YELLOW: '#FFBF07',
  BLUE: '#4771FF',
};

const sensorStatus = {
  NOT_SUPPORTED: '不支持',
  NORMAL: '正常',
  FAULT: '故障',
  NOT_CONNECTED: '未连接',
};

@Component({
  components: {
    DeviceAdd,
    Edit,
    Tip,
    AImage,
    ExcelError,
    DownloadQrcode,
    Upgrade,
    ComponySelect,
  },
})
export default class HatTable extends Vue {
  @Prop({ type: Object, default: () => {} }) record;
  project = [];
  selectedRows = [];
  data = [];
  hatColors = hatColors;

  pagination = {
    'pageSize': 10,
    'current': 1,
    'total': 1,
    'showSizeChanger': true,
    'pageSizeOptions': ['10', '20', '30', '40'],
    'showTotal': total => `总数 ${total} `,
    'show-quick-jumper': true,
  };
  loading = false;
  status = [
    { name: '未激活', key: 'UNACTIVE' },
    { name: '在线', key: 'ONLINE' },
    { name: '离线', key: 'OFFLINE' },
    { name: '待机', key: 'STANDBY' },
  ];
  searchData = {
    content: undefined,
    projectId: undefined,
    tenantId: undefined,
    status: undefined,
    sorter: undefined,
  };

  versions = [
    { name: '未激活', key: 'UNACTIVE' },
    { name: '在线', key: 'ONLINE' },
    { name: '离线', key: 'OFFLINE' },
    { name: '待机', key: 'STANDBY' },
  ];
  getlistByPage = false;
  mounted() {
    this.getList();
    this.getProjects();
  }
  @Watch('searchData', { deep: true })
  setStatus() {
    this.$store.commit('hat/setStatus', this.searchData.status);
  }
  async showUpgrade() {
    const deviceIds = this.selectedRows.map(item => {
      return item.deviceId;
    });
    if (!this.selectedRows.length) {
      this.$message.warning('请至少选择一台设备！');
      return;
    }
    const model = await createModal(
      () => (
        <Upgrade onClose={() => model.handleClose()} record={{ deviceIds }} />
      ),
      {
        title: '设备OTA升级',
        width: '1180px',
      },
    );
  }
  clearSelected() {
    this.$store.commit('hat/setStatus', null);
    Object.keys(this.searchData).forEach(item => {
      this.searchData[item] = undefined;
    });
    this.searchByContent();
  }
  @debounce(300)
  searchByDeviceName() {
    this.pagination.current = 1;
    this.getList();
  }
  secretShow(record) {
    record.secretShow = !record.secretShow;
  }
  deviceStatus(status) {
    return {
      UNACTIVE: '未激活',
      OFFLINE: '离线',
      ONLINE: '在线',
      STANDBY: '待机',
    }[status];
  }
  async getList() {
    this.selectedRowKeys = [];
    this.selectedRows = [];
    this.loading = true;
    let { current, pageSize: size } = this.pagination;
    let { content, projectId, tenantId, sorter } = this.searchData;
    let status = this.$store.state.hat.status;
    if (status && !this.getlistByPage) {
      current = 1;
    }
    var resuqeObj = {
      current,
      size,
    };
    content && (resuqeObj.content = content);
    projectId && (resuqeObj.projectId = projectId);
    status && (resuqeObj.status = status);
    tenantId && (resuqeObj.tenantId = tenantId);
    sorter && (resuqeObj.sorter = sorter);
    try {
      const result = await getDeviceList(resuqeObj);
      const newList = result.records.map(item => {
        return { ...item, secretShow: false };
      });
      this.data = newList;
      this.pagination.total = result.total;
    } catch (error) {
      this.$message.warn('获取列表失败!');
    }
    this.$attrs.update();
    this.loading = false;
    // this.selectedRows = [];
    this.getlistByPage = false;
  }
  get columns() {
    return [
      {
        customRender: (text, row, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: '序号',
        align: 'center',
        fixed: 'left',
        width: '70px',
      },
      {
        title: '设备号',
        width: '250px',
        dataIndex: 'deviceName',
        ellipsis: true,
        scopedSlots: { customRender: 'deviceName' },
      },
      {
        title: '验证码',
        width: '170px',
        scopedSlots: { customRender: 'deviceSecretIntercept' },
      },
      {
        title: '设备状态',
        dataIndex: 'status',
        width: '120px',
        scopedSlots: { customRender: 'status' },
        ellipsis: true,
      },
      {
        title: '所属企业',
        dataIndex: 'tenantName',
        width: '200px',
        ellipsis: true,
      },
      {
        title: '当前所属项目',
        dataIndex: 'projectName',
        width: '200px',
        ellipsis: true,
        scopedSlots: { customRender: 'projectName' },
      },
      {
        title: '当前佩戴人员',
        width: '150px',
        ellipsis: true,
        scopedSlots: { customRender: 'userName' },
      },
      {
        title: '产品分类',
        dataIndex: 'type',
        width: '120px',
        ellipsis: true,
        scopedSlots: { customRender: 'type' },
      },
      {
        title: '固件版本',
        dataIndex: 'firmwareVersion',
        width: '120px',
        ellipsis: true,
      },
      {
        title: '设备信息',
        width: '120px',
        ellipsis: true,
        scopedSlots: { customRender: 'detailUrl' },
      },
      {
        title: '添加时间',
        dataIndex: 'createTime',
        key: 'create_time',
        width: 180,
        ellipsis: true,
        sorter: true,
        customRender: text =>
          text ? this.$moment(text).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        title: '最近上线',
        dataIndex: 'lastOnlineTime',
        key: 'last_online_time',
        width: 180,
        ellipsis: true,
        sorter: true,
        customRender: text =>
          text ? this.$moment(text).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        title: '设备有效期',
        dataIndex: 'expireDate',
        key: 'expire_date',
        width: 180,
        ellipsis: true,
        sorter: true,
        customRender: text =>
          text ? this.$moment(text).format('YYYY-MM-DD') : '',
      },
      {
        title: 'ICCID',
        dataIndex: 'iccId',
        width: 150,
        ellipsis: true,
      },
      {
        title: '脱帽传感器',
        width: 150,
        ellipsis: true,
        customRender: (text, record) => sensorStatus[record.sensor.takeOffHat],
      },
      {
        title: '加速计',
        width: 150,
        ellipsis: true,
        customRender: (text, record) => sensorStatus[record.sensor.gyroscope],
      },
      {
        title: '近电传感器',
        width: 150,
        ellipsis: true,
        customRender: (text, record) =>
          sensorStatus[record.sensor.nearElectricity],
      },
      {
        title: '麦克风',
        width: 150,
        ellipsis: true,
        customRender: (text, record) => sensorStatus[record.sensor.deviceMic],
      },
      {
        title: '摄像头',
        width: 150,
        ellipsis: true,
        customRender: (text, record) =>
          sensorStatus[record.sensor.deviceCamera],
      },
      {
        title: '气体传感器',
        width: 150,
        ellipsis: true,
        customRender: (text, record) =>
          sensorStatus[record.sensor.harmfulGases],
      },
      {
        title: '心率传感器',
        width: 150,
        ellipsis: true,
        customRender: (text, record) => sensorStatus[record.sensor.heartRate],
      },
      {
        title: '血压传感器',
        width: 150,
        ellipsis: true,
        customRender: (text, record) =>
          sensorStatus[record.sensor.bloodPressure],
      },
      {
        title: '血氧传感器',
        width: 150,
        ellipsis: true,
        customRender: (text, record) => sensorStatus[record.sensor.bloodOxygen],
      },
      {
        title: '语音识别',
        width: 150,
        ellipsis: true,
        dataIndex: 'speechRecognition',
        customRender: text => (text ? '支持' : '不支持'),
      },
      {
        title: '操作',
        width: '180px',
        fixed: 'right',
        scopedSlots: { customRender: 'operation' },
      },
    ];
  }
  async deviceIn() {
    try {
      const result = await createFormModal(() => <DeviceAdd />, {
        width: '500px',
        title: '添加设备',
      });
      if (result) {
        await this.addDecive1(result);
        setTimeout(() => {
          this.getList();
        }, 500);
        this.getList();
      }
    } catch {
      return false;
    }
  }
  async addDecive1(form) {
    const { form: devices, idxTenantId } = form;
    try {
      const result = await addDeviceText({ devices, idxTenantId });
      const { type } = result;
      var tips = {
        EXIST() {
          const devices = result.devices;
          this.$message.warn(<ExcelError record={{ devices }} />, 3);
        },
        FORMAT() {
          this.$message.error('添加失败，格式异常!');
        },
      };
      if (type) {
        tips[type].call(this);
      } else {
        this.$message.success('添加设备成功!');
      }
    } catch (error) {
      this.$message.error('添加设备失败!');
    }
  }
  async edit(record) {
    try {
      await createFormModal(() => <Edit record={record} />, {
        width: '800px',
        title: '编辑设备',
      });
      setTimeout(() => {
        this.getList();
      }, 500);
    } catch {
      return false;
    }
  }

  handleSelectProject() {}
  Detail() {}
  deleteDevice(id) {
    createFormModal(
      () => (
        <Tip iconStyle={{ padding: '0 0 22px' }}>
          <template slot="txt">
            <span>确认删除？</span>
          </template>
          <span slot="subTxt">将彻底删除当前设备以及所有数据，请再三确认!</span>
        </Tip>
      ),
      {
        title: '提示',
        onOk: async () => {
          await this.deleteDevices(id);
          this.$message.success('删除成功！');
          this.pagination.current = 1;
          this.getList();
        },
      },
    );
  }
  removeDevice(id) {
    createFormModal(
      () => (
        <Tip iconStyle={{ padding: '0 0 22px' }}>
          <template slot="txt">
            <span>确认移除改设备？</span>
          </template>
          <span slot="subTxt">移除后，当前企业将不再能查看和管理该设备</span>
        </Tip>
      ),
      {
        title: '提示',
        onOk: async () => {
          await this.removeDeviceById(id);
          this.$message.success('移除成功！');
          this.pagination.current = 1;
          this.getList();
        },
      },
    );
  }
  async removeDeviceById(id) {
    return new Promise(resolve => {
      let allRequest = [];
      if (this.selectedRows.length) {
        this.selectedRows.forEach(item => {
          allRequest.push(reqRemoveDevice(item.deviceId));
        });
      }
      allRequest.push(reqRemoveDevice(id));
      Promise.all(allRequest).then(() => {
        resolve();
      });
    });
  }
  async deleteDevices(id) {
    return new Promise(resolve => {
      let allRequest = [];
      if (this.selectedRows.length) {
        this.selectedRows.forEach(item => {
          allRequest.push(reqDeleteDevice(item.deviceId));
        });
      }
      allRequest.push(reqDeleteDevice(id));
      Promise.all(allRequest).then(() => {
        resolve();
      });
    });
  }
  deviceInfo(record) {
    createFormModal(() => <DeviceInfo ref="deviceInfo" record={record} />, {
      width: '442px',
      title: '设备信息',
      onOk: async () => {},
      footer: () => [
        <AButton ghost onClick={this.copy(record)}>
          复制设备信息
        </AButton>,
        <a-button type="primary" onClick={this.handleDownload}>
          下载二维码
        </a-button>,
      ],
    });
  }
  copy(record) {
    const that = this;
    return function() {
      let {
        deviceName,
        deviceSecretIntercept,
        color,
        tenantName,
        projectName,
        userName,
        type,
        firmwareVersion,
        expireDate,
      } = record;
      type = { BASE: '基础版', PLUS: '升级版' }[type];
      expireDate = that.$moment(expireDate).format('YYYY-MM-DD HH:mm:ss');
      color = {
        YELLOW: '黄色',
        BLUE: '蓝色',
        RED: '红色',
        WHITE: '白色',
        ORANGE: '橙色',
      }[color];
      let str = `设备号:${deviceName}\n验证码:${deviceSecretIntercept}\n颜色:${color}\n所属企业:${tenantName}\n当前所属项目:${projectName}
当前佩戴人员:${userName}\n产品分类:${type}\n固件版本:${firmwareVersion}\n有效期:${expireDate}`;
      clipboardWrite(str).then(() => {
        that.$message.success('复制成功');
      });
    };
  }

  handleDownload() {
    this.$refs.deviceInfo.down();
  }
  showSizeChange(page, filters, sorter) {
    this.pagination.current = page.current;
    this.pagination.pageSize = page.pageSize;
    this.getlistByPage = true;
    if (sorter.order) {
      const sortType = {
        ascend: 'ASC',
        descend: 'DESC',
      };
      this.searchData.sorter = `${sorter.columnKey} ${sortType[sorter.order]}`;
    } else {
      this.searchData.sorter = undefined;
    }
    this.getList();
  }
  selectedRowKeys = [];
  rowSelection() {
    const { selectedRowKeys } = this;
    return {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.selectedRowKeys = selectedRowKeys;
        this.selectedRows = selectedRows;
      },
    };
  }
  async Management() {
    this.$message.warn('正在开发中!');
  }
  @debounce(300)
  searchByContent() {
    this.pagination.current = 1;
    this.getList();
  }
  async getProjects() {
    const result = await reqGetProject({ current: 1, size: 100 });
    this.project = result.records;
  }
  clearChecked() {
    let trs = document.querySelectorAll('tbody tr .ant-checkbox');
    trs.forEach(item => {
      item.classList.remove('ant-checkbox-checked');
    });
  }
  downloadQrcodes() {
    if (this.selectedRows.length === 0) {
      this.$message.warning('请至少选择一台设备！');
      return;
    }
    createModal(() => <DownloadQrcode infoList={this.selectedRows} />, {
      width: 650,
      title: '下载',
      className: this.$style.ManageModel,
    });
  }
  /** 批量分配企业 */
  async batchAllocation() {
    if (this.selectedRows.length === 0) {
      this.$message.warning('请至少选择一台设备！');
      return;
    }
    const deviceIds = this.selectedRows
      .filter(item => !item.tenantId)
      .map(item => item.deviceId);
    try {
      const result = await createFormModal(
        () => <BatchAllocation deviceIds={deviceIds} />,
        {
          width: 443,
          maskClosable: false,
          title: '操作确认',
        },
      );
      if (result) {
        this.getList();
      }
    } catch {
      return false;
    }
  }
  async voiceRecognition() {
    try {
      if (this.selectedRows.length === 0) {
        this.$message.warning('请至少选择一台设备！');
        return false;
      }
      const arr = this.selectedRows.filter(
        v => v.type === 'PLUS' && v.status === 'ONLINE',
      );
      if (arr.length === 0) {
        this.$message.warn(
          '语音识别仅支持plus（升级版）设备，激活时需要设备在线！',
        );
        return false;
      }
      const list = arr.map(item => ({
        id: item.deviceId,
        name: item.userName,
        deviceCode: item.deviceName,
      }));
      const result = await createFormModal(
        () => <VoiceRecognition devices={list} />,
        {
          width: 550,
          title: '操作确认',
          okText: '激活',
        },
      );
      if (result && result.length > 0) {
        await speechRecognition(result);
        this.$message.success('操作成功！');
        this.getList();
      }
    } catch (error) {
      return false;
    }
  }
}
</script>
<style lang="less" module>
.wrap {
  padding-top: 20px;
  .iconStyle {
    font-size: 20px;
    margin-top: 10px;
  }
  .bottom {
    display: flex;
    transform: translateY(-50px);
    width: 40%;
  }
  .model {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;

    .btn {
      margin-right: 20px;
    }
  }
  .export {
    color: var(--font);
    font-size: 15px;
    & > span {
      margin-left: 5px;
    }
  }
  .export:hover {
    cursor: pointer;
    color: var(--primary);
  }
}
.deviceCell {
  display: flex;
  align-items: center;

  span:last-of-type {
    width: 32px;
    height: 16px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
  .plusTxt {
    font-size: 20px;
    font-weight: bolder;
    transform: scale(0.5);
    margin: 0 !important;
    color: #ffffff;
  }
}
</style>
