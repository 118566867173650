<template>
  <div>
    <div :class="$style.rightContent_top">
      <div :class="$style.rightContent_top_left">
        <div :class="$style.left_item">
          <a-form-model
            ref="form"
            :model="currentClass"
            :rules="rules"
            :class="$style.form"
            layout="inline"
          >
            <a-form-model-item
              :label="$t('activity.name') + '：'"
              prop="className"
              :maxLength="30"
            >
              <a-input
                :class="$style.left_box"
                v-model.trim="currentClass.className"
                :disabled="!$p.action('UPDATE')"
              />
            </a-form-model-item>
          </a-form-model>

          <!-- <div :class="[$style.left_box]">
            <a-input v-model="currentClass.className" />
          </div> -->
        </div>
        <div :class="$style.left_item">
          <div>{{ $t('activity.InvitationType') }}:</div>
          <a-radio-group
            v-model="currentClass.type"
            :class="$style.left_box"
            :disabled="currentClass.classId !== ''"
          >
            <a-radio :value="ActivityType.ONLINE_ACTIVITY">
              {{ ActivityTypeText[ActivityType.ONLINE_ACTIVITY] }}
            </a-radio>
            <a-radio :value="ActivityType.OFFLINE_ACTIVITY">
              {{ ActivityTypeText[ActivityType.OFFLINE_ACTIVITY] }}
            </a-radio>
          </a-radio-group>
        </div>
        <div :class="$style.left_item">
          {{ $t('activity.numberInvitation') }}：
          <a-input-number
            v-model="currentClass.codeCount"
            style="width:160px;"
            :min="1"
            :max="1000"
            :disabled="currentClass.classId !== ''"
          />
        </div>
      </div>
      <div>
        <async-button
          v-if="currentClass.classId !== ''"
          :class="$style.copyCodeClass"
          :click="copyCodeClass"
        >
          {{ $t('activity.copyLink') }}
        </async-button>
        <async-button
          type="primary"
          v-if="currentClass.classId !== '' && $p.action('UPDATE')"
          :click="editName"
        >
          {{ $t('activity.modify') }}
        </async-button>

        <async-button
          type="primary"
          v-if="currentClass.classId === '' && $p.action('CREATE')"
          :click="addSubmit"
        >
          {{ $t('activity.okAdd') }}
        </async-button>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ActivityTypeText, ActivityType } from '@/enum/activity';
import AsyncButton from '@/components/async-button';
import { clipboardWrite } from '@triascloud/utils';
import { createModuleUrl } from '@/utils/createModuleUrl';

@Component({
  components: {
    AsyncButton,
  },
})
export default class CodeParameters extends Vue {
  @Prop({ type: Object }) currentClass;
  ActivityTypeText = ActivityTypeText;
  ActivityType = ActivityType;
  async addSubmit() {
    await this.$refs.form.validate();
    this.$emit('addSubmit', this.currentClass);
  }
  async editName() {
    await this.$refs.form.validate();
    this.$emit('editName', this.currentClass);
  }

  createActivityUrl() {
    return process.env.VUE_APP_ENV === 'production'
      ? 'https://www.swanwu.cn'
      : createModuleUrl('www');
  }

  async copyCodeClass() {
    await clipboardWrite(
      `${this.createActivityUrl()}/activity/online?codeClassId=${
        this.currentClass.classId
      }`,
    );
    this.$message.success(this.$t('common.tips.copy'));
  }
  rules = {
    className: [
      {
        min: 2,
        max: 30,
        message: this.$t('record.groupNameTip'),
        trigger: 'blur',
      },
      {
        required: true,
        trigger: 'blur',
        message: '请输入邀请码名称',
      },
    ],
  };
}
</script>
<style lang="less" module>
.rightContent_top {
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid var(--border);
}
.form {
  :global {
    .ant-form-item.ant-form-item-with-help {
      margin-bottom: 0px;
    }
  }
}
.rightContent_top_left {
  display: flex;
}

.left_item {
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.left_box {
  padding: 9px 10px;
  border-radius: 6px;
  margin-left: 10px;
}
.left_border {
  border: 1px solid var(--border);
}
.copyCodeClass {
  margin-right: 10px;
}
</style>
