import { createModuleUrl as _createModuleUrl } from './createModuleUrl';
export const createModuleUrl = _createModuleUrl;
export function safeParseJSON(json, fallback) {
  if (!json || typeof json !== 'string') return fallback;
  try {
    return JSON.parse(json);
  } catch (e) {
    return fallback;
  }
}

export const REG_VALID_INPUT = /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0]|\d|\w|_|\/)*$/;

export const validInputRule = message => ({
  pattern: REG_VALID_INPUT,
  message,
});

/**
 * @name 将元素滚动到视图内
 * @param { Element } dom
 */
export function scrollIntoView(dom) {
  if (!dom) return;
  if (dom.scrollIntoViewIfNeed) {
    dom.scrollIntoViewIfNeed(true);
  } else if (dom.scrollIntoView) {
    dom.scrollIntoView({
      smooth: true,
      block: 'center',
      inline: 'center',
    });
  }
}

/**
 * @name 去除对象中的空值
 * @param { Object } object
 * @returns
 */
export function emptyValueFilter(object) {
  let _obj = {};
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      if (![undefined, null, ''].includes(object[key])) {
        _obj[key] = object[key];
      }
    }
  }
  return _obj;
}
