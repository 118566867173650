import moment from 'moment';
import AntDesignVue, { Modal } from 'ant-design-vue';
import { BaseService } from '@triascloud/services';
import XComponents from '@triascloud/x-components';
import { thenable } from '@triascloud/utils';
import { globalVueI18n } from '@triascloud/i18n';
import router from '@/router';
import store from '@/store';
import request from './services/request';
import closeableMessage from './components/closeable-message';

export default function install(Vue) {
  // 全局注册ant-design-vue
  Vue.use(AntDesignVue);
  // 全局注册x-components
  Vue.use(XComponents, {
    scriptUrl: `//cdn.${process.env.VUE_APP_HOST}/icon/20240322aaOvRVTdJQ.js`,
    scriptColorUrl: `//cdn.${process.env.VUE_APP_HOST}/icon/20221019aaYSSWNPU5.js`,
  });
  XComponents.setCommonExtraVueOptions({
    router,
    store,
  });
  // 为Service注入request
  BaseService.request = request;
  Vue.config.productionTip = false;
  ['info', 'success', 'error', 'warning', 'confirm'].forEach(method => {
    Vue.prototype[`$${method}`] = generateModalMethod(method);
  });
  Vue.prototype.$message = closeableMessage;
  Vue.prototype.$moment = moment;
  Vue.prototype.$preview = XComponents.Preview.createModal;
  Vue.prototype.$developing = XComponents.Developing.createModal;
}

/**
 * @param { 'info' | 'success' | 'error' | 'warning' | 'confirm' } method
 */
const generateModalMethod = method => (content, title, options) =>
  new Promise((resolve, reject) => {
    const contentOptions = typeof content === 'string' ? { content } : content;
    const titleOptions = typeof content === 'string' ? { title } : title;
    const mixedOptions = Object.assign(
      {},
      options,
      titleOptions,
      contentOptions,
    );
    Modal[method]({
      okText: globalVueI18n.translate('common.action.confirm'),
      cancelText: globalVueI18n.translate('common.action.cancel'),
      ...mixedOptions,
      onOk: () =>
        thenable(mixedOptions.onOk && mixedOptions.onOk()).then(() =>
          resolve(),
        ),
      onCancel: () =>
        thenable(mixedOptions.onCancel && mixedOptions.onCancel()).then(() =>
          reject(new Error('user cancel')),
        ),
    });
  });
