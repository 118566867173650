<template>
  <div :class="$style.index">
    <div :class="$style.toolbar">
      <span>
        {{ $t('activity.winningCode') }}：
        <a-input :class="$style.toolbar__input" v-model="searchData.award">
        </a-input>
      </span>
      <span>
        {{ $t('activity.names') }}：
        <a-input :class="$style.toolbar__input" v-model="searchData.userName">
        </a-input>
      </span>
      <!-- <span>
          {{ $t('activity.InvitationType') }}：
          <a-radio-group v-model="searchData.codeType" :class="$style.left_box">
            <a-radio :value="''"> 全部</a-radio>
            <a-radio :value="4"> 线下</a-radio>
            <a-radio :value="3"> 线上</a-radio>
          </a-radio-group>
        </span> -->
      <span>
        时间：
        <a-range-picker
          show-time
          value-format="x"
          :class="$style.toolbar__picker"
          :value="
            searchData.startTime
              ? [searchData.startTime, searchData.endTime]
              : []
          "
          @change="handleRangeTimeChange"
        />
      </span>
      <span>
        {{ $t('activity.whetherWin') }}：
        <a-select
          :placeholder="$t('common.label.all')"
          :class="$style.toolbar__select"
          v-model="searchData.isWin"
        >
          <a-select-option
            v-for="item in templateTypes"
            :key="item.value"
            :value="item.value"
            >{{ item.text }}</a-select-option
          >
        </a-select>
      </span>
      <a-button
        :class="$style.toolbar__button"
        @click="handleSearch"
        type="info"
      >
        <span>{{ $t('activity.Inquire') }}</span>
      </a-button>
      <a-button
        :class="$style.toolbar__button"
        type="primary"
        @click="handleReset"
      >
        {{ $t('activity.reset') }}
      </a-button>
    </div>
    <x-data-table
      ref="table"
      base-path="/marketing/activity/award/winning/code/list"
      row-key="award"
      :columns="columns"
      :service="service"
      :class="$style.table"
      :custom-row="
        record => ({
          on: {
            click: () => openDetail(record),
          },
          style: {
            cursor: 'pointer',
          },
        })
      "
      :row-selection="{
        selectedRowKeys,
        onChange: keys => (selectedRowKeys = keys),
      }"
    >
      <div slot="headerLeft"></div>
      <template slot="action">
        <a-button type="link" @click="handleExport" v-if="$p.action('EXPORT')">
          <span>{{ $t('inviteCode.export') }}</span>
        </a-button>
        <!-- <a-button @click="handleSearch" type="info">
          <span>{{ $t('activity.Inquire') }}</span>
        </a-button>
        <a-button type="primary" @click="handleReset">
          {{ $t('activity.reset') }}
        </a-button> -->
        <a-button type="primary" v-if="$p.action('UPDATE')" @click="batchSet">
          批量调整
        </a-button>
        <a-button type="primary" v-if="$p.action('UPDATE')" @click="quickSet">
          抽取中奖码
        </a-button>
        <async-button
          v-if="$p.action('UPDATE')"
          type="primary"
          :click="refreshUser"
        >
          更新用户信息
        </async-button>
      </template>
    </x-data-table>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from 'moment';
import {
  winningLotteryList,
  exportActivityCode,
  updateUser,
} from '@/services/marketing/invite-code';
import { DataSourceService } from '@triascloud/services';
import WinningCodeDetails from './winning-code-details.vue';
import { AwardTypeText } from '@/enum/activity';
import ExtractAwardCodeModal from './extract-award-code-modal.vue';
import BatchSetAwardModal from './batch-set-award-modal.vue';
import AsyncButton from '@/components/async-button';

@Component({
  components: { AsyncButton },
})
export default class ManagementList extends Vue {
  @Prop({ type: String, default: '' }) activityId;
  selectedRowKeys = [];
  searchData = {
    award: '',
    codeType: '',
    userName: '',
    isWin: '',
    startTime: '',
    endTime: '',
  };
  templateTypes = [
    {
      text: '全部',
      value: '',
    },
    {
      text: '已中奖',
      value: '2',
    },
    {
      text: '未中奖',
      value: '3',
    },
  ];

  get columns() {
    return [
      {
        title: this.$t('activity.winningCode'),
        dataIndex: 'award',
      },
      // {
      //   dataIndex: 'inviteCode',
      //   key: 'inviteCode',
      //   title: this.$t('activity.RelatedCodes'),
      // },
      // {
      //   key: 'inviteCodeType',
      //   title: this.$t('activity.relatedType'),
      //   dataIndex: 'inviteCodeType',
      //   filter: false,
      //   sorter: false,
      //   width: 130,
      //   customRender: res => ActivityTypeText[res],
      // },
      {
        title: this.$t('activity.linkedAccount'),
        key: 'account',
        dataIndex: 'account',
        filter: false,
        sorter: false,
      },
      {
        title: this.$t('activity.associatedWeChat'),
        dataIndex: 'weChat',
        key: 'weChat',
      },
      {
        title: this.$t('activity.associatedName'),
        dataIndex: 'userName',
        key: 'userName',
      },
      {
        title: this.$t('activity.phoneAssociate'),
        dataIndex: 'userPhone',
        key: 'userPhone',
      },
      {
        title: '获取时间',
        dataIndex: 'createTime',
        key: 'createTime',
        customRender: checkDate =>
          checkDate ? moment(checkDate).format('Y-MM-DD HH:mm') : '-',
      },
      {
        title: this.$t('activity.whetherWin'),
        dataIndex: 'isWin',
        key: 'isWin',
        customRender: win => (AwardTypeText[win] ? AwardTypeText[win] : '-'),
      },

      {
        title: this.$t('common.action.operating'),
        key: 'actions',
        customRender: record => {
          return (
            <span
              onClick={e => {
                e.stopPropagation();
                this.openDetail(record);
              }}
            >
              调整
            </span>
          );
        },
      },
    ].map(item => ({
      ...item,
      width: item.width || 110,
      sorter: false,
      filter: false,
      align: 'left',
      ellipsis: false,
    }));
  }

  async handleExport() {
    await exportActivityCode({
      ...this.searchData,
      activityId: this.activityId,
    });
  }
  handleReset() {
    this.searchData = {
      award: '',
      codeType: '',
      userName: '',
      isWin: '',
      startTime: '',
      endTime: '',
    };
  }
  handleSearch() {
    this.$refs.table.loadData(this.searchData);
  }

  quickSet() {
    ExtractAwardCodeModal.createModal();
  }

  async refreshUser() {
    await updateUser();
    this.$message.success('更新成功');
    this.$refs.table.loadData();
  }

  async batchSet() {
    if (!this.selectedRowKeys.length)
      return this.$message.warning('请选择抽奖码');
    await BatchSetAwardModal.createModal({ awards: this.selectedRowKeys });
    this.selectedRowKeys = [];
    this.$refs.table.loadData();
  }

  total = 0;
  get service() {
    const service = new DataSourceService(
      '/marketing/activity/award/winning/code/list',
    );
    service.getList = ({ skip, size }) =>
      winningLotteryList({
        activityId: this.activityId,
        current: skip / size + 1,
        size,
        ...this.searchData,
      }).then(res => {
        res.data = res.records;
        return res;
      });
    return service;
  }

  refreshData() {
    this.$refs.table.loadData({ activityId: this.activityId });
  }
  async openDetail(data) {
    await WinningCodeDetails.createDrawer(
      { data: data },
      {
        refresh: isWin => {
          data.isWin = isWin;
        },
      },
    );
  }
  handleRangeTimeChange(range) {
    if (range && range.length) {
      this.searchData.startTime = range[0];
      this.searchData.endTime = range[1];
    } else {
      this.searchData.startTime = '';
      this.searchData.endTime = '';
    }
  }
}
</script>
<style lang="less" module>
.table {
  flex: 1;
  overflow: hidden;
  :global(.x-data-table--action .ant-btn-primary) {
    color: #fff;
    margin-left: 10px;
  }
}
.index {
  display: flex;
  overflow: hidden;
  padding: 20px;
  flex: 1;
  flex-direction: column;
}

.action {
  cursor: pointer;
  color: var(--font-active);
  &.action--delete {
    color: var(--delete);
    margin-left: 20px;
  }
}
.toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  .toolbar__input {
    width: 120px;
    margin-right: 30px;
    :global .ant-input {
      width: 110px;
      transition: width 0.5s;
      &:focus {
        width: 200px;
      }
    }
  }
  .toolbar__picker {
    width: 220px;
    margin: 0 10px 0;
    :global {
      .ant-calendar-picker-icon {
        display: none;
      }
      .ant-calendar-picker-input.ant-input {
        padding-left: 10px;
        text-align: center;
      }
    }
  }
  .toolbar__button {
    margin-left: 10px;
  }
}

.toolbar__select {
  width: 95px;
}
</style>
