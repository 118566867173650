<template>
  <div :class="$style.library" @click="handleClickLibrary">
    <div v-if="!iconList.length" :class="$style.empty">
      <x-icon type="tc-icon-empty-box" />
    </div>
    <div v-else :class="$style.iconList">
      <icon v-for="icon in iconList" :key="icon.pkId" :icon="icon" />
    </div>
    <div :class="$style.libraryMessage">
      <p :class="$style.title">
        <x-ellipsis :class="$style.text">{{ data.iconLibraryName }}</x-ellipsis>
        <span :class="$style.button">
          <x-icon
            type="vertical-align-top"
            v-if="$p.action('UPDATE') && !recycle"
            @click.stop="iconTop"
          />
          <x-icon
            type="edit"
            v-if="$p.action('UPDATE') && !recycle"
            @click.stop="handleEditForm"
            :title="$t('common.action.edit')"
          />

          <x-icon
            type="undo"
            v-else-if="$p.action('RECYCLE-BIN') && recycle"
            @click.stop="handleRecoveryForm"
            :title="$t('iconLibrary.library.undo')"
          />
          <x-icon
            type="tc-icon-delete"
            @click.stop="handleDeleteLibrary"
            :title="$t('common.action.delete')"
            v-if="$p.action('DELETE')"
          />
        </span>
      </p>
      <p :class="$style.type">
        {{ $t('common.label.belongGroupName') }}: {{ iconType }}
      </p>
      <p :class="$style.type" v-if="recycle">
        {{ $t('dictionary.deletePerson') }}: {{ data.deleteName }}
      </p>
      <p :class="$style.type" v-if="recycle">
        {{ $t('common.label.deleteDate') }}:{{ getTime(data.deleteDate) }}
      </p>
      <p :class="$style.info">
        <span :class="$style.message">
          <x-icon type="folder" /><span :class="$style.primary">{{
            data.iconNum
          }}</span>
          <x-icon type="link" /><span :class="$style.primary">{{
            data.totalQuoteNum
          }}</span>
        </span>
        <a-tooltip
          v-if="$p.action('UPDATE') && !recycle"
          :title="$t('market.template.classify.move')"
        >
          <x-icon
            :class="$style.change"
            type="tc-icon-change"
            @click.stop="handleMoveLibrary"
          ></x-icon>
        </a-tooltip>
      </p>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import Icon from './icon';
import EditPopover from './edit-popover';
import IconForm from './icon-form';
import MoveLibrary from './move-library';
import IconDetail from './detail/index';
import { createFormModal } from '@triascloud/x-components';
import {
  removeLibrary,
  deleteLibrary,
  updateLibrary,
  recoverLibrary,
  iconAlignTop,
} from '@/services/resource/icon-library';

@Component({
  components: { Icon, EditPopover },
})
export default class IconLibrary extends Vue {
  @Prop() data;
  @Prop({ type: Boolean, default: false }) recycle;

  get iconList() {
    if (!this.data || !this.data.icons) return [];
    return this.data.icons.records || [];
  }

  /** @name 修改图标库 */
  async handleEditForm() {
    const result = await IconForm.createModal(
      { data: this.data },
      {
        title: this.$t('iconLibrary.library.edit'),
      },
    );
    await updateLibrary(result);
    this.$message.success(this.$t('common.tips.edit'));
    this.data.iconLibraryName = result.libraryName;
  }

  /** @name 恢复图标库 */
  async handleRecoveryForm() {
    await createFormModal(
      () => (
        <div class={this.$style.delete}>
          <XIcon type="tc-icon-warning" class={this.$style.icon} />
          <p>{this.$t('iconLibrary.library.tips.restore')}</p>
        </div>
      ),
      { title: this.$t('common.label.confirmRecovery'), width: 420 },
    );
    const { iconLibraryName, iconLibraryType, pkId } = this.data;
    await recoverLibrary({
      deleted: 0,
      libraryName: iconLibraryName,
      libraryType: iconLibraryType,
      pkId,
    });
    await this.$message.success(this.$t('common.tips.recoverySuccess'));
    await this.refreshLibraryList();
  }

  /** @name 打开图标库详情 */
  async handleClickLibrary() {
    if (this.recycle) return;
    await IconDetail.createDetailDrawer(
      {
        library: this.data,
      },
      {
        refreshLibraryList: () => this.refreshLibraryList(),
      },
      this.data.iconLibraryName,
    );
  }
  async iconTop() {
    await iconAlignTop({ pkId: this.data.pkId });
    this.refreshLibraryList();
  }

  /** @name 刷新图标库列表 */
  refreshLibraryList() {
    this.$emit('change');
  }

  /** @name 时间戳格式化 */
  getTime(time) {
    return this.$moment(time).format('YYYY-MM-DD HH:mm:ss');
  }

  /** @name 删除图标库 */
  async handleDeleteLibrary() {
    if (!this.recycle) {
      /** @name 普通删除 */
      await createFormModal(
        () => (
          <div class={this.$style.delete}>
            <XIcon type="tc-icon-warning" class={this.$style.icon} />
            <p>{this.$t('iconLibrary.library.tips.delete')}</p>
          </div>
        ),
        { title: this.$t('common.tips.confirmDelete'), width: 420 },
      );
      const { iconLibraryName, iconLibraryType, pkId } = this.data;
      await deleteLibrary({
        deleted: 1,
        libraryName: iconLibraryName,
        libraryType: iconLibraryType,
        pkId,
      });
    } else {
      /** @name 彻底删除 */
      await createFormModal(
        () => (
          <div>
            <XIcon type="tc-icon-warning" class={this.$style.icon} />
            <p>{this.$t('iconLibrary.library.tips.completelyDelete')}</p>
          </div>
        ),
        {
          title: this.$t('iconLibrary.library.label.completelyDelete'),
          width: 420,
          wrapClassName: this.$style.delete,
        },
      );
      const { pkId } = this.data;
      await removeLibrary(pkId);
    }

    await this.$message.success(this.$t('common.tips.delete'));
    await this.refreshLibraryList();
  }

  get iconLibraryType() {
    return {
      OFFICIAL: this.$t('iconLibrary.library.type.official'),
      MONOCHROMATIC: this.$t('iconLibrary.library.type.monochrome'),
      MULTICOLOR: this.$t('iconLibrary.library.type.multiColor'),
    };
  }

  get iconType() {
    return this.iconLibraryType[this.data.iconLibraryType];
  }

  async handleMoveLibrary() {
    const result = await MoveLibrary.createModal(
      { data: this.data },
      {
        title: this.$t('iconLibrary.library.move'),
      },
    );
    await updateLibrary(result);
    await this.$message.success(this.$t('common.tips.edit'));
    await this.refreshLibraryList();
  }
}
</script>
<style lang="less" module>
.library {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 0px 14px 0px var(--shadow);
  padding-bottom: 10px;
  border-radius: 4px;
  min-height: 260px;

  &:hover {
    box-shadow: 0px 0px 14px 0px var(--primary-60);
  }

  .iconList {
    padding: 0;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, 30px);
    gap: 5px 20px;
    justify-content: space-between;
    margin-bottom: 15px;
    height: 145px;
    align-content: flex-start;
  }

  .empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 145px;
    margin-bottom: 15px;

    i {
      font-size: 90px;
      color: var(--font-info);
    }
  }

  .libraryMessage {
    width: 100%;
  }

  .title {
    font-size: 14px;
    color: var(--font);
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .text {
      flex: 1;
    }
    .button {
      display: none;
      i {
        cursor: pointer;
      }
      i:first-of-type {
        color: var(--primary);
      }
      i:last-of-type {
        color: var(--delete);
      }
      i + i {
        margin-left: 15px;
      }
    }

    &:hover {
      .button {
        display: block;
        flex-shrink: 0;
      }
    }
  }

  .type {
    font-size: 14px;
    color: var(--font-sub);
    margin-bottom: 5px;
  }

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .message {
      font-size: 14px;
      color: var(--font-sub);
      .primary {
        color: var(--primary);
        margin-left: 10px;
        margin-right: 15px;
      }
    }
    .change {
      color: #aaaaaa;
      display: none;
      cursor: pointer;
    }

    &:hover {
      .change {
        display: block;
      }
    }
  }
}
.delete {
  text-align: center;
  .icon {
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 30px;
    color: #fe5959;
  }
  p {
    font-size: 16px;
    color: var(--font);
  }
}
</style>
