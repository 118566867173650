<template>
  <a-form-model ref="form" :model="config">
    <a-form-item :label="$t('solution.configLabel')">
      <a-radio-group v-model="config.allVisible" @change="handleVisibleChange">
        <a-radio :value="1">
          {{ $t('solution.allShow') }}
        </a-radio>
        <a-radio :value="0">
          {{ $t('solution.partiallyVisible') }}
          <a v-if="!config.allVisible" @click="handleCompanyConfigChange">{{
            $t('solution.select')
          }}</a>
        </a-radio>
      </a-radio-group>
    </a-form-item>
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import ImgItem from './img-item.vue';
import { createFormModal } from '@triascloud/x-components';
import CompanySelect from './company-select.vue';
import {
  getSolutionVisible,
  solutionVisibleSetting,
} from '@/services/template-manager/solution';
import { globalVueI18n } from '@triascloud/i18n';

function createCompanyConfig(id) {
  return createFormModal(h => h(CompanyConfig, { props: { solutionId: id } }), {
    title: globalVueI18n.translate('solution.settingTips'),
    width: 440,
  });
}

@Component({
  components: {
    ImgItem,
  },
})
export default class CompanyConfig extends Vue {
  @Prop({ type: String }) solutionId;
  static create = createCompanyConfig;
  config = {
    allVisible: 1,
    solutionId: '',
    members: [],
  };

  async handleCompanyConfigChange() {
    const res = await CompanySelect.create(this.config.members);
    this.config.members = res;
  }

  handleVisibleChange() {
    if (this.config.allVisible === 1) {
      this.config.members = [];
    }
  }

  async getValue() {
    await solutionVisibleSetting(this.config);
    this.$message.success(this.$t('common.tips.operationSuccess'));
  }
  async created() {
    const res = await getSolutionVisible(this.solutionId);

    if (!res) {
      this.config.solutionId = this.solutionId;
    } else {
      this.config = res;
    }
  }
}
</script>
<style lang="less" module></style>
