import request from '../request';

export const basePath = '/iot-smart-helmet/system/device';

const deviceIn = '/iot-smart-helmet/system/device/importDevice';
const projectUrl = '/smart-site/project/tenant/page';
/** @name del */
export const getDeviceList = data => {
  return request(`${basePath}/list`, {
    method: 'GET',
    body: data,
  });
};

/** @name 批量激活语音识别 */
export const speechRecognition = data => {
  return request(`${basePath}/speechRecognition`, {
    method: 'PUT',
    body: data,
  });
};

export const addDevice = ({ file, signal }) => {
  return request(`${deviceIn}`, {
    method: 'post',
    body: file,
    signal,
  });
};

export const addDeviceText = ({
  devices = [],
  type = 'BASE',
  idxTenantId = '',
}) => {
  var body = {
    devices,
    type,
    idxTenantId,
  };
  return request(`${basePath}`, {
    method: 'post',
    body,
  });
};
export const getDeviceById = deviceId => {
  return request(`${basePath}/${deviceId}`, {
    method: 'get',
  });
};
export const reqChangeDevice = body => {
  return request(`${basePath}`, {
    method: 'put',
    body,
  });
};
export const reqDeleteDevice = deviceId => {
  return request(`${basePath}/${deviceId}`, {
    method: 'delete',
  });
};
export const reqRemoveDevice = deviceId => {
  return request(`${basePath}/${deviceId}`, {
    method: 'put',
  });
};
export const reqDevicesCount = () => {
  return request(`${basePath}/count`, {
    method: 'get',
  });
};
export const reqProjectName = () => {
  return request(`/smart-site/project/list`, {
    method: 'get',
  });
};
// 最新获取项目名称接口
export const reqGetProject = body => {
  const { current, size } = body;
  return request(`${projectUrl}?current=${current}&size=${size}`, {
    method: 'get',
  });
};
// 最新获取企业名称接口
export const reqGetCompany = body => {
  return request('/tenant-center/tenant/enterprise/page', {
    method: 'GET',
    body,
  });
};
// 批量绑定企业
export const batchBindTenant = body => {
  return request(`${basePath}/batchBindTenant`, {
    method: 'PUT',
    body,
  });
};
// 获取设备列表
export function getSystemDeviceList(data) {
  return request(`${basePath}/systemDeviceNameList`, {
    method: 'GET',
    body: data,
  });
}
