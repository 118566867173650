<template>
  <div>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item :label="$t('common.tips.textAddress')" prop="text">
        <a-textarea
          v-model="form.text"
          :auto-size="{ minRows: 2, maxRows: 8 }"
        />
      </a-form-model-item>

      <a-form-model-item>
        <div slot="label">
          <span>{{
            `${$t('website.homePage.icon')}(${icons.length || 0}${$t(
              'common.label.piece',
            )})`
          }}</span>
        </div>
        <x-scroll-view :class="$style.scrollIcon">
          <div :class="$style.iconContainer">
            <a-button
              type="link"
              v-for="(item, index) in icons"
              :key="index"
              :class="$style.item"
              :style="{
                border: selectIndex.includes(item.id)
                  ? '1px solid var(--primary)'
                  : 'none',
              }"
              @click="clickItem(item)"
            >
              <!-- <span v-html="item.show_svg"></span> -->
              <i
                :class="[importJson.colorful ? null : $style.common]"
                v-html="item.show_svg"
              ></i>
              <div :class="$style.name">
                <x-ellipsis style="width:100%">
                  {{ item.name }}
                </x-ellipsis>
              </div>
            </a-button>
          </div>
        </x-scroll-view>
      </a-form-model-item>
    </a-form-model>

    <div :class="$style.footer">
      <div>
        <a-checkbox @change="onChange" :defaultChecked="true">
          {{ $t('common.action.selectAll') }}
        </a-checkbox>
        <a-checkbox @change="onChangeColor" :defaultChecked="false">
          {{ $t('common.colorIcon') }}
        </a-checkbox>
      </div>
      <div>
        <a-button :class="$style.btn" @click="handleCancel">
          {{ $t('common.action.cancel') }}
        </a-button>
        <a-button :class="$style.btn" type="primary" @click="handleCreate">
          {{ $t('common.action.confirm') }}
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import { importIcon } from '@/services/resource/icon';

@Component({
  components: {},
})
export default class ImportIconModal extends Vue {
  static createImportIconModal = createImportIconModal;

  form = {
    text: '',
  };
  importJson = null;
  selectItem = [];
  selectIndex = [];

  get icons() {
    return (
      this.importJson && this.importJson.data && this.importJson.data.icons
    );
  }

  @Watch('form.text')
  onWatchText(val) {
    if (val) {
      this.importJson = JSON.parse(val);
      this.importJson.data.icons.forEach(item => {
        this.selectItem.push(item);
        this.selectIndex.push(item.id);
      });
    } else {
      this.importJson = null;
      this.selectItem = [];
      this.selectIndex = [];
    }
  }

  onChange(e) {
    if (e.target.checked) {
      this.importJson.data.icons.forEach(item => {
        this.selectItem.push(item);
        this.selectIndex.push(item.id);
      });
    } else {
      this.selectItem = [];
      this.selectIndex = [];
    }
  }

  onChangeColor(e) {
    this.importJson.colorful = e.target.checked;
    this.$forceUpdate();
  }

  handleCancel() {
    this.$confirm({
      title: this.$t('common.action.savePrompt'),
      content: () => <div>{this.$t('common.action.notSavedTips')}</div>,
      onOk: () => {
        this.$emit('close');
      },
      onCancel() {},
      class: 'test',
    });
  }
  handleCreate() {
    this.$refs.form.validate(valid => {
      if (valid) {
        this.importJson.data.icons = this.selectItem;
        importIcon(this.importJson).then(res => {
          if (res) {
            this.$message.success(this.$t('common.tips.operationSuccess'));
          } else {
            this.$message.success(this.$t('common.fail'));
          }
          this.$emit('handleCreate');
        });
      } else {
        return false;
      }
    });
  }

  clickItem(item) {
    let status = false;
    this.selectItem.forEach((el, i) => {
      if (el.id === item.id) {
        this.selectItem.splice(i, 1);
        status = true;
        this.selectIndex.forEach((em, k) => {
          if (em === item.id) this.selectIndex.splice(k, 1);
          return;
        });
        return;
      }
    });
    if (!status) {
      this.selectItem.push(item);
      this.selectIndex.push(item.id);
    }
  }

  get rules() {
    return {
      text: [
        {
          required: true,
          message: this.$t('common.placeholder.input'),
        },
      ],
    };
  }
}

function createImportIconModal(props) {
  return new Promise(resolve => {
    const modal = createModal(
      h =>
        h(ImportIconModal, {
          props,
          on: {
            close: () => {
              modal.handleClose();
              resolve();
            },
            handleCreate: () => {
              modal.handleClose();
              resolve('success');
            },
          },
        }),
      {
        width: 860,
        title: props.modalInfo.title,
      },
    );
  });
}
</script>

<style lang="less" module>
.scrollIcon {
  height: auto;
  max-height: 400px;
  width: 100%;
}
.iconContainer {
  padding: 10px 10px;
  display: flex;
  flex-wrap: wrap;
  .item {
    background: var(--main-bg);
    font-size: 38px;
    // flex: 0 0 20%;
    // margin: 20px 2.5%;
    flex: 0 0 80px;
    height: 80px;
    margin: 20px 16px;
    .name {
      font-size: 12px;
      text-align: center;
      color: var(--font);
    }
  }
}

.common {
  svg {
    > path {
      fill: var(--font-sub);
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  margin-bottom: 0;
  .btn {
    margin-right: 10px;
  }
}
</style>
