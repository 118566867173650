import { globalVueI18n } from '@triascloud/i18n';

export const ActivityType = {
  /**
   * 普通邀请码
   */
  NORMAL: 1,
  /**
   * 用户活动邀请码（邀请码虽然属于特定企业，但是也归属特定用户，只有特定用户才能分享此邀请码）
   */
  USER_ACTIVITY: 2,
  /**
   * 线上活动
   */
  ONLINE_ACTIVITY: 3,
  /**
   * 线下活动
   */
  OFFLINE_ACTIVITY: 4,
};
export const AwardType = {
  /**
   * 未中奖
   */
  Null_Award: 0,
  /**
   * 神秘奖
   */
  Special_Award: 1,
  /**
   * 一等奖
   */
  First_Award: 2,
  /**
   * 二等奖
   */
  Second_Award: 3,
  /**
   * 三等奖
   */
  Third_Award: 4,
  /**
   * 幸运奖
   */
  Mystery_Award: 5,
};

export const ActivityTypeText = {
  [ActivityType.NORMAL]: globalVueI18n.translate('activity.normalInvitation'),
  [ActivityType.USER_ACTIVITY]: globalVueI18n.translate(
    'activity. activityInvitation',
  ),
  [ActivityType.ONLINE_ACTIVITY]: globalVueI18n.translate(
    'activity.onlineActivities',
  ),
  [ActivityType.OFFLINE_ACTIVITY]: globalVueI18n.translate(
    'activity.OfflineActivity',
  ),
};

export const AwardTypeText = {
  [AwardType.Null_Award]: globalVueI18n.translate('activity.didNotwin'),
  [AwardType.Special_Award]: globalVueI18n.translate('activity.mysteryAward'),
  [AwardType.First_Award]: globalVueI18n.translate('activity.firstPrize'),
  [AwardType.Second_Award]: globalVueI18n.translate('activity.secondPrize'),
  [AwardType.Third_Award]: globalVueI18n.translate('activity.thirdPrize'),
  [AwardType.Mystery_Award]: globalVueI18n.translate('activity.luckyPrize'),
};
